<template>
  <div>
    <b-modal
      ref="search-digital-modal"
      title="GLOBAL SEARCH DIGITAL"
      v-model="modalSearch"
      size="xlg"
      title-class="h3 font-weight-bolder text-white"
      modal-class="modal-primary text-center"
      hide-footer
      @hidden="closeModal"
      title-tag="h3"
    >
      <!-- table -->
      <b-table
        small
        ref="refGlobalSearch"
        class="position-relative table-new-customization"
        :items="myProvider"
        :fields="fields"
        primary-key="id"
        table-class="text-nowrap"
        responsive="sm"
        show-empty
        sticky-header="70vh"
        :busy="isBusy"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1"></b-spinner>
            <strong>Loading ...</strong>
          </div>
        </template>

        <template #cell(lead_name)="data">
          <b-button
            variant="outline"
            class="pl-1"
            :class="isDarkSkin ? 'text-warning' : 'text-primary'"
            @click="validateAcces(data.item)"
          >
            {{ data.item.lead_name }}
          </b-button>
          <br />
          <status-account style="margin-left: 15px" :account="data.item" />
        </template>

        <template #cell(created_at)="data">
          <p>
            {{ data.item.created_at | myGlobal }}
          </p>
        </template>

        <template #cell(account)="data">
          <p>{{ data.item.account }}</p>
        </template>

        <template #cell(ispayment)="data">
          <p :class="data.item.ispayment == 1 ? 'text-success' : 'text-danger'">
            {{ data.item.ispayment == 1 ? "YES" : "NO" }}
          </p>
        </template>

        <template #cell(call_round)="data">
          <div>
            <b-badge variant="light-warning" class="text-capitalize w-75">{{
              data.item.call_round
            }}</b-badge>
          </div>
        </template>

        <template #cell(call_round_date)="data">
          <p>{{ data.item.call_round_date | myGlobal }}</p>
        </template>
      </b-table>
      <modal-validate-access
        v-if="modalStateV"
        :client-data="clientData"
        @closeModal="closeModal2"
      />
    </b-modal>
  </div>
</template>

<script>
// Import Data
import fieldsData from "./fieldsSearchDigital";
import SearchDiditalCE from "../services/search-digital";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";
import StatusPayment from "@/views/commons/components/clients/stylescomponents/StatusPayment.vue";
import ModalValidateAccess from "./ModalValidateAccess.vue";
import { mapGetters } from "vuex";
export default {
  components: { StatusAccount, StatusPayment, ModalValidateAccess },
  props: {
    searchData: {
      type: Object,
    },
  },
  data() {
    return {
      modalSearch: this.stateShearch,
      data: [],
      fields: fieldsData,
      isBusy: false,
      modalStateV: false,
      clientData: {},
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  mounted() {
    this.toggleModal("search-digital-modal");
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    closeModal2() {
      this.modalStateV = false;
    },
    // If in call rounds
    async inCallRounds(item) {
      if (item.motive_type != 1) {
        const { data, status } = await SearchDiditalCE.getClientCallRound({
          client_account_id: item.client_accounts_id,
        });
        if (status == 200) {
          if (data.length) {
            return data[0].motive_id;
          } else {
            return 2;
          }
        }
      } else {
        return item.motive_type;
      }
    },
    //Modal
    async validateAcces(item) {
      if (
        // Validate user type
        this.currentUser.role_id == 1 ||
        this.currentUser.role_id == 2 ||
        this.currentUser.role_id == 14 ||
        this.currentUser.role_id == 17
      ) {
        this.addPreloader();
        this.$router.push({
          name: "ce-customer-service-dashboard",
          params: {
            idClient: item.client_accounts_id,
            hasAccess: true,
            typeAccess: await this.inCallRounds(item),
            wayAccess: 2,
          },
        });
        this.removePreloader();
      } else {
        this.modalStateV = true;
        let result = {
          lead_name: item.lead_name,
          client_id: item.client_id,
          client_account_id: item.client_accounts_id,
          call_round_id: item.call_round_id,
          typeAccess: await this.inCallRounds(item),
          wayAccess: 1, //search
          hasAccess: true,
        };
        this.clientData = result;
      }
    },
    async myProvider() {
      try {
        const params = {
          name_text: this.searchData.search,
        };
        const { data, status } = await SearchDiditalCE.searchDigital(params);
        if (status == 200) {
          return data;
        }
      } catch (error) {
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong with tracking!"
        );
        throw error;
      }
    },
  },
};
</script>

<style scoped>
#icon {
  border-radius: 50%;
  margin-bottom: 2px;
  margin-right: 5px;
}
.my {
  margin: 6px 0 0 6px;
}
</style>
