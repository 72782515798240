export default [
    {
      // [0]
      key: "client",
      label: "Client",
      // thClass: "text-center",
      tdClass: "text-left",
      visible: true,
    },
    {
      // [1]
      key: "agent",
      label: "Agent",
      visible: true,
    },
    {
      // [2]
      key: "start_time",
      label: "Start Date",
      visible: true,
      sortable: true,
    },
    {
      // [3]
      key: "end_time",
      label: "Finished Date",
      visible: true,
      sortable: true
    },
    {
      // [4]
      key: "duration",
      label: "Conversation Duration",
      thClass: "text-left",
      visible: true,
      sortable: true
    },
    {
      // [6]
      key: "note",
      label: "Note",
      visible: true,
    },
  ]
  