export default [
    {
      /* [ 0 ] */
      type: "datepicker",
      margin: true,
      showLabel: true,
      label: "From",
      placeholder: "Date",
      class: "font-small-3",
      model: null,
      locale: "en",
      dateFormatOptions: {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      },
      cols: 6,
    },
    {
      /* [ 1 ] */
      type: "datepicker",
      margin: true,
      showLabel: true,
      label: "To",
      placeholder: "Date",
      class: "font-small-3",
      model: null,
      locale: "en",
      dateFormatOptions: {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      },
      cols: 6,
    },
    {
      /* [ 2 ] */
      type: "select",
      margin: true,
      showLabel: true,
      label: "Agent",
      model: null,
      options: [],
      reduce: "id",
      selectText: "name",
      cols: 12,
    },
  ]
  