<template>
  <b-modal
    :title="formatedTitle"
    ref="commission-list-cs"
    modal-class="modal-primary"
    title-tag="h4"
    @hidden="hideModal"
    size="lg"
  >
    <b-table
      :fields="fields"
      :items="tableProvider"
      ref="ctable"
      sticky-header="22rem"
      style="height: 22rem"
      show-empty
    >
      <template #empty>
        <div class="text-center text-primary my-2">
          <strong>No commissions found</strong>
        </div>
      </template>
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-2"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
      <template #cell(client)="data">
        <span>{{ data.value }}</span
        ><br />
        <small class="text-warning">{{ data.item.account }}</small>
      </template>
      <template #cell(created_at)="data">
        <span>{{ data.value | myGlobalWithHour }}</span>
      </template>
      <template #cell(amount)="data">
        <span>$ {{ (Number(data.value) * 0.8).toFixed(2) }}</span>
      </template>
    </b-table>
  </b-modal>
</template>

<script>
import { amgApi } from "@/service/axios";
import { mapGetters } from "vuex";
import moment from "moment";
import CommissionsService from "@/views/commons/components/commissions/services/commissions.service";

export default {
  props: { show: Boolean, info: Object },
  // info.type string
  // info.agent_id string
  data() {
    return {
      isActive: false,
      fields: [
        {
          key: "client",
          label: "Client",
        },
        {
          key: "type",
          label: "Type",
        },
        {
          key: "created_at",
          label: "Date",
        },
        // {
        // 	key: "amount",
        // 	label: "Amount",
        // },
      ],
    };
  },
  computed: {
    ...mapGetters({ currentUser: "auth/currentUser" }),
    formatedTitle() {
      return `${this.info.type} Commisions`.toUpperCase();
    },
    parseType() {
      switch (this.info.type) {
        case "actions":
          return 1;
        case "charges":
          return 2;
        case "services":
          return 3;
      }
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  methods: {
    async tableProvider() {
      if (this.parseType == 3) {
        let body = {
          user: this.currentUser.user_id,
          role: this.currentUser.role_id,
          module: 6,
          year: moment().format("YYYY"),
          month: moment().format("MM"),
        };
        let res = await CommissionsService.searchCommissionsUserDepartment(
          body
        );
        res = res.filter((item) => Number(item.commission) > 0);

        return res.map((item) => ({
          account: item.account,
          amount: Number(item.commission),
          client: item.lead,
          created_at: item.created_at,
          type: "add/change",
        }));
      }
      let body = {
        agent_id: this.info.agent_id,
        base_date: moment().format("YYYY-MM-DD"),
        type: this.parseType,
        module_id: this.moduleId,
      };
      let res = await amgApi.post(
        "/credit-experts-digital/agent-commision-list",
        body
      );

      return res.data;
    },
    hideModal() {
      this.$emit("closing");
    },
  },
  mounted() {
    this.toggleModal("commission-list-cs");
  },
};
</script>
