<template>
  <div>
    <header-slot />
    <b-row class="mb-2">
      <b-col class="">
        <SearchDigital class="" />
      </b-col>
      <b-col>
        <b-row>
          <b-col class="mr-1">
            <b-form-select
              v-model="mainDate"
              :options="monthOpts"
              @input="fetchFullDashboard"
            />
          </b-col>
          <b-col>
            <b-form-select
              class="mb-1"
              v-model="year"
              :options="years"
              @input="fetchFullDashboard"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12" lg="4" v-if="isAgent">
        <b-card class="p-2 rounded-lg" no-body>
          <b-overlay :show="loadingCalls" :variant="skin">
            <div class="d-flex align-items-center mb-2" style="gap: 1rem">
              <div
                class="p-1 rounded"
                :class="skin == 'dark' ? 'bg-dark' : 'bg-light'"
              >
                <feather-icon icon="PhoneIcon" size="30" />
              </div>
              <span class="h2" :class="skin == 'dark' ? 'text-white' : ''"
                >Calls</span
              >
            </div>
            <div class="box-container box-container-2col">
              <div
                class="p-1 rounded text-center"
                :class="skin == 'dark' ? 'bg-dark' : 'bg-light'"
              >
                <h4 class="commision__subtitle">Monthly</h4>
                <hr />
                <span class="commision__amount">{{ calls.monthly }}</span>
              </div>
              <div
                class="p-1 rounded text-center"
                :class="skin == 'dark' ? 'bg-dark' : 'bg-light'"
              >
                <h4 class="commision__subtitle">Daily</h4>
                <hr />
                <span class="commision__amount">{{ calls.daily }}</span>
              </div>
            </div>
          </b-overlay>
        </b-card>
      </b-col>
      <b-col sm="12" :lg="isAgent ? '4' : '6'">
        <b-card class="p-2 rounded-lg" no-body>
          <b-overlay :show="loadingCommisions" :variant="skin">
            <div class="d-flex align-items-center mb-2" style="gap: 1rem">
              <div
                class="p-1 rounded"
                :class="skin == 'dark' ? 'bg-dark' : 'bg-light'"
              >
                <feather-icon icon="DollarSignIcon" size="30" />
              </div>
              <span class="h2" :class="skin == 'dark' ? 'text-white' : ''"
                >Commissions
              </span>
            </div>
            <div
              :class="{
                'box-container': isAgent,
                'box-container-1col': isAssistant,
              }"
            >
              <div
                class="p-1 rounded text-center cursor-pointer"
                :class="skin == 'dark' ? 'bg-dark' : 'bg-light'"
                @click="openModalCommisions('actions')"
              >
                <h4 class="commision__subtitle">Actions</h4>
                <hr />
                <span class="text-nowrap commision__amount"
                  >$ {{ commisions.actions.toFixed(2) }}</span
                >
              </div>
              <div
                class="p-1 rounded text-center cursor-pointer"
                :class="skin == 'dark' ? 'bg-dark' : 'bg-light'"
                @click="openModalCommisions('charges')"
                v-if="isAgent"
              >
                <h4 class="commision__subtitle">Charges</h4>
                <hr />
                <span class="text-nowrap commision__amount"
                  >$ {{ commisions.charges.toFixed(2) }}</span
                >
              </div>
              <div
                class="p-1 rounded text-center cursor-pointer"
                :class="skin == 'dark' ? 'bg-dark' : 'bg-light'"
                @click="openModalCommisions('services')"
                v-if="isAgent"
              >
                <h4 class="commision__subtitle">Add/Change</h4>
                <hr />
                <span class="text-nowrap commision__amount"
                  >$ {{ commisions.sales.toFixed(2) }}</span
                >
              </div>
            </div>
          </b-overlay>
        </b-card>
      </b-col>
      <b-col sm="12" :lg="isAgent ? '4' : '6'">
        <b-card class="p-2" no-body>
          <b-overlay :show="loadingCommisions" :variant="skin">
            <div
              class="d-flex justify-content-between align-items-start flex-column flex-md-row"
              style="min-height: 14rem"
            >
              <div
                class="d-flex align-items-center commision-cont"
                style="gap: 1rem"
              >
                <b-avatar
                  variant="info"
                  size="4rem"
                  src="https://placekitten.com/300/300"
                ></b-avatar>
                <div class="text-md-left text-sm-right">
                  <h2 class="mb-1">{{ currentUser.fullName }}</h2>
                  <span class="h4">{{ currentUser.role_name }}</span>
                </div>
              </div>
              <div
                class="mt-2 p-1 rounded text-center commision-box"
                :class="skin == 'dark' ? 'bg-dark' : 'bg-light'"
              >
                <span class="commision__subtitle--big">Commission Amount:</span>
                <hr />
                <span class="commission-text"
                  >$ {{ commisions.total.toFixed(2) }}</span
                >
              </div>
            </div>
          </b-overlay>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card class="p-2 rounded-lg" no-body>
          <b-overlay :show="loadingWeekly" :variant="skin">
            <div class="d-flex align-items-center mb-2" style="gap: 1rem">
              <div
                class="p-1 rounded"
                :class="skin == 'dark' ? 'bg-dark' : 'bg-light'"
              >
                <feather-icon icon="CalendarIcon" size="30" />
              </div>
              <span
                class="h2"
                :class="skin == 'dark' ? 'text-white' : ''"
                style="flex-grow: 1"
                >Weekly Productivity</span
              >
              <b-form-datepicker
                style="width: 40%"
                v-model="weekly"
                @input="fetchWeeklyGraph"
              />
            </div>
            <VueApexCharts
              width="100%"
              height="300"
              :options="areaOpts"
              :series="areaOpts.series"
            />
          </b-overlay>
        </b-card>
      </b-col>
      <b-col>
        <b-card class="p-2 rounded-lg" no-body>
          <b-overlay :show="loadingMonthly" :variant="skin">
            <div class="d-flex align-items-center mb-2" style="gap: 1rem">
              <div
                class="p-1 rounded"
                :class="skin == 'dark' ? 'bg-dark' : 'bg-light'"
              >
                <feather-icon icon="CalendarIcon" size="30" />
              </div>
              <span
                class="h2"
                :class="skin == 'dark' ? 'text-white' : ''"
                style="flex-grow: 1"
                >Monthly Productivity</span
              >
              <b-form-select
                style="width: 40%"
                :value="new Date().getFullYear()"
                :options="yearOpts"
                disabled
              />
            </div>
            <VueApexCharts
              width="100%"
              height="300"
              :options="barOpts"
              :series="barOpts.series"
            />
          </b-overlay>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col v-if="isAssistant">
        <b-card class="p-2 rounded-lg" no-body>
          <div class="d-flex align-items-center mb-2" style="gap: 1rem">
            <div
              class="p-1 rounded"
              :class="skin == 'dark' ? 'bg-dark' : 'bg-light'"
            >
              <feather-icon icon="MessageCircleIcon" size="30" />
            </div>
            <span class="h2" :class="skin == 'dark' ? 'text-white' : ''"
              >Messages</span
            >
          </div>
          <b-table
            :items="messagesProvider"
            :fields="messagesFields"
            sticky-header="22rem"
            style="height: 22rem"
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading ...</strong>
              </div>
            </template>
          </b-table>
        </b-card>
      </b-col>
      <b-col v-if="isAgent">
        <b-card class="p-2 rounded-lg" no-body>
          <div class="d-flex align-items-center mb-2" style="gap: 1rem">
            <div
              class="p-1 rounded"
              :class="skin == 'dark' ? 'bg-dark' : 'bg-light'"
            >
              <feather-icon icon="AlertTriangleIcon" size="30" />
            </div>
            <span class="h2" :class="skin == 'dark' ? 'text-white' : ''"
              >Call Rounds</span
            >
          </div>
          <CallRounds />
        </b-card>
      </b-col>
    </b-row>
    <CommisionsList
      v-if="showModalCommisions"
      :info="modalCommisionsInfo"
      @closing="showModalCommisions = false"
    />
  </div>
</template>

<script>
import moment from "moment";
import { amgApi } from "@/service/axios";
import { mapGetters } from "vuex";
import VueApexCharts from "vue-apexcharts";
import CommissionsService from "@/views/commons/components/commissions/services/commissions.service";
import revisionService from "@/views/ce-digital/sub-modules/customer-service/views/chat-revision/services/chat-revision.service";

import SearchDigital from "@/views/commons/components/search-digital/SearchDigital.vue";
import CallRounds from "./components/CallRounds.vue";
import CommisionsList from "@/views/ce-digital/sub-modules/customer-service/views/dashboard-agent/components/CommisionsList.vue";
export default {
  components: { VueApexCharts, CallRounds, SearchDigital, CommisionsList },
  data() {
    return {
      calls: {
        monthly: 0,
        daily: 0,
      },
      loadingCalls: false,
      commisionsMonth: moment().format("YYYY-MM-01"),
      loadingCommisions: false,
      barOpts: {
        chart: { type: "bar", toolbar: { show: false }, background: "#0000" },
        theme: { mode: "dark" },
        series: [],
      },
      weekly: moment().format("YYYY-MM-DD"),
      loadingWeekly: false,
      areaOpts: {
        chart: { type: "area", toolbar: { show: false }, background: "#0000" },
        theme: { mode: "dark" },
        series: [],
      },
      monthly: moment().format("YYYY-MM-01"),
      loadingMonthly: false,
      monthOpts: [],
      yearOpts: ["2022", "2023", "2024", "2025"], // xd
      commisions: {
        actions: 0,
        charges: 0,
        sales: 0,
        total: 0,
      },
      mainDate: moment().format("YYYY-MM-01"),
      showModalCommisions: false,
      modalCommisionsInfo: {},
      messagesFields: [
        {
          key: "user_name",
          label: "user",
        },
        {
          key: "client_name",
          label: "client",
        },
        {
          key: "accept",
          label: "chat start",
        },
        {
          key: "finish",
          label: "chat end",
        },
      ],
      years: [],
      year: new Date().getFullYear(),
    };
  },
  computed: {
    ...mapGetters({ currentUser: "auth/currentUser", skin: "appConfig/skin" }),
    isAssistant() {
      return this.currentUser.role_id == 16;
    },
    isAgent() {
      return this.currentUser.role_id == 15;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  methods: {
    async fetchAgentCommisions() {
      let body = {
        agent_id: this.currentUser.user_id,
        base_date: this.mainDate,
        module_id: this.moduleId,
        year: `${this.year}-01-01`,
      };
      this.loadingCommisions = true;
      let res = await amgApi.post(
        "/credit-experts-digital/get-agent-commisions",
        body
      );
      // For add/change
      const params = {
        user: this.currentUser.user_id,
        role: this.currentUser.role_id,
        module: 6,
        year: this.year,
        month: this.commisionsMonth.split("-")[1],
      };
      let resAC = await CommissionsService.searchCommissionsUserDepartment(
        params
      );
      let acTotal = resAC.reduce((a, b) => a + Number(b.commission), 0);
      this.commisions.sales = acTotal * 0.8;
      // ----
      this.commisions.actions = Number(res.data.actions);
      this.commisions.charges = Number(res.data.charges);
      this.commisions.total =
        Number(res.data.actions) +
        Number(res.data.charges) +
        Number(acTotal * 0.8);

      this.loadingCommisions = false;
    },
    async fetchWeeklyGraph() {
      this.loadingWeekly = true;
      let res = await amgApi.post("/credit-experts-digital/weekly-list", {
        base_date: this.weekly,
        agent_id: this.currentUser.user_id,
        role_id: this.currentUser.role_id,
      });
      this.areaOpts.series = [
        {
          data: res.data.map(({ work_day, quantity }) => {
            return { x: work_day, y: quantity };
          }),
        },
      ];
      this.loadingWeekly = false;
    },
    async fetchMonthlyGraph() {
      this.loadingMonthly = true;
      let res = await amgApi.post("/credit-experts-digital/annual-list", {
        base_date: this.monthly,
        agent_id: this.currentUser.user_id,
        role_id: this.currentUser.role_id,
      });
      this.barOpts.series = [
        {
          data: res.data.map(({ work_month, quantity }) => {
            return { x: work_month, y: quantity };
          }),
        },
      ];
      this.loadingMonthly = false;
    },
    async fetchAgentCallsCount() {
      this.loadingCalls = true;
      let res = await amgApi.post("/credit-experts-digital/agent-callrounds", {
        agent_id: this.currentUser.user_id,
      });

      this.calls = {
        monthly: res.data.monthly,
        daily: res.data.daily,
      };
      this.loadingCalls = false;
    },
    fetchFullDashboard() {
      this.fetchAgentCallsCount();
      this.fetchAgentCommisions();
    },
    openModalCommisions(type) {
      this.modalCommisionsInfo = {
        type: type,
        agent_id: this.currentUser.user_id,
      };
      this.showModalCommisions = true;
    },
    async messagesProvider() {
      const params = {
        status: 2,
        page: 1,
        perPage: 10,
        search: "",
      };
      let res = await revisionService.getCommissions(params);
      return res.data;
    },
    setYears() {
      let currentYear = new Date().getFullYear();
      for (let i = 2016; i <= currentYear; i++) {
        this.years.push({ value: i, text: i });
      }
    },
  },
  created() {
    this.setYears();
    let year = new Date().getFullYear();
    this.monthOpts = [
      { text: "January", value: `${year}-01-01` },
      { text: "February", value: `${year}-02-01` },
      { text: "March", value: `${year}-03-01` },
      { text: "April", value: `${year}-04-01` },
      { text: "May", value: `${year}-05-01` },
      { text: "June", value: `${year}-06-01` },
      { text: "July", value: `${year}-07-01` },
      { text: "August", value: `${year}-08-01` },
      { text: "September", value: `${year}-09-01` },
      { text: "October", value: `${year}-10-01` },
      { text: "November", value: `${year}-11-01` },
      { text: "December", value: `${year}-12-01` },
    ];

    // Set component themes
    this.barOpts.theme.mode = this.skin;
    this.areaOpts.theme.mode = this.skin;

    // -----------
    this.fetchAgentCallsCount();
    this.fetchAgentCommisions();
    this.fetchWeeklyGraph();
    this.fetchMonthlyGraph();
  },
};
</script>

<style scoped>
.box-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: clamp(1rem, 1vw, 2rem);
}

.box-container-1col {
  grid-template-columns: 1fr;
}

.box-container-2col {
  grid-template-columns: 1fr 1fr;
}

.commision__subtitle {
  font-size: clamp(1rem, 1vw, 1.5rem);
}

.commision__subtitle--big {
  font-size: clamp(1.2rem, 1.5vw, 2rem);
}

.commision__amount {
  font-size: clamp(1.2rem, 2vw, 2rem);
}

.commission-text {
  font-size: clamp(2rem, 2vw, 3rem);
}

@media only screen and (max-width: 1024px) {
  .box-container {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 768px) {
  .commision-cont {
    width: 100%;
    justify-content: space-between;
  }
  .commision-box {
    width: 100%;
  }
}
</style>
