<template>
  <div>
    <header-slot />
    <filter-slot
      :filter="filters"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="$refs['refReportTable'].refresh()"
      @reset-all-filters="$refs['refReportTable'].refresh()"
    >
      <b-table
        slot="table"
        class="position-relative font-small-3"
        primary-key="id"
        empty-text="No matching records found"
        select-mode="multi"
        responsive="sm"
        table-class="text-nowrap"
        small
        sort-icon-left
        ref="refReportTable"
        :items="myProvider"
        :fields="fields"
        :current-page="paginate.currentPage"
        :per-page="paginate.perPage"
        :busy.sync="isBusy"
        show-empty
        sticky-header="68vh"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>

        <template #cell(client)="data">
          <div>
            <router-link
              class="text-warning"
              :to="{
                name: 'ce-customer-service-dashboard',
                params: {
                  idClient: data.item.client_id,
                },
              }"
              target="_blank"
              >{{ data.item.client }}</router-link
            >

            <div>{{ data.item.client_account }}</div>
          </div>
        </template>

        <template #cell(agent)="data">
          <div class="mr-jo">
            <span> {{ data.item.agent }}</span>
          </div>
        </template>

        <template #cell(start_time)="data">
          <div class="mr-jo">
            <span> {{ data.item.start_time | myGlobalWithHour }}</span>
          </div>
        </template>

        <template #cell(end_time)="data">
          <div class="mr-jo">
            <span>{{ data.item.end_time | myGlobalWithHour }}</span>
          </div>
        </template>

        <template #cell(duration)="data">
          <div class="w-100 mr-jo">
            <div class="">
              <b-badge variant="light-info" pill>
                {{ data.item.hours != 0 ? data.item.hours : "" }}
                {{
                  data.item.hours != 0
                    ? data.item.hours == 1
                      ? "Hour"
                      : "Hours"
                    : ""
                }}
                {{ data.item.minutes != 0 ? data.item.minutes : "" }}
                {{
                  data.item.minutes != 0
                    ? data.item.minutes == 1
                      ? "Minute"
                      : "Minutes"
                    : ""
                }}
                {{ data.item.seconds }} Seconds</b-badge
              >
            </div>
          </div>
        </template>

        <template #cell(note)="data">
          <div class="mr-jo text-primary">
            <feather-icon
              icon="FileTextIcon"
              class="cursor-pointer"
              @click="openModalNote(data.item.text_note, data.item)"
            ></feather-icon>
          </div>
        </template>
        <template #custom-foot>
          <b-tr>
            <b-td colspan="2"></b-td>
            <b-td colspan="2" class="text-right font-weight-bolder py-px">
              Sub-total
            </b-td>
            <b-td colspan="1" class="text-left py-px">
              <b-badge> {{ convertSeconds(subTotalSeconds) }}</b-badge>
            </b-td>
            <b-td colspan="10" />
          </b-tr>
          <b-tr>
            <b-td colspan="2"></b-td>
            <b-td colspan="2" class="text-right font-weight-bolder py-px">
              Total
            </b-td>
            <b-td colspan="1" class="text-left py-px">
              <b-badge>{{ convertSeconds(totalSeconds) }}</b-badge>
            </b-td>
            <b-td colspan="10" />
          </b-tr>
        </template>
      </b-table>
    </filter-slot>
    <b-modal
      @hiden="showNote = false"
      v-if="showNote"
      v-model="showNote"
      hide-footer
      :title="textClient"
    >
      <div class="p-2 rounded card-note">
        <div class="clearfix mb-2">
          <div class="float-left font-weight-bolder">
            {{ dataNote.start_time | myDateText }}
          </div>
          <div class="float-right">
            <span class="text-info">
              {{ dataNote.type_note }}
            </span>
          </div>
        </div>
        <span>
          {{ regexNoHTML(textNote) }}
        </span>
        <div class="clearfix mt-2">
          <span class="float-right text-agent">
            {{
              "By " +
              dataNote.agent +
              ", " +
              $options.filters.myGlobalWithHour(dataNote.end_time)
            }}
          </span>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Fields from "@/views/ce-digital/sub-modules/customer-service/views/reports/data/fields.data.js"
import Filters from "@/views/ce-digital/sub-modules/customer-service/views/reports/data/filters.data.js"
import ReportService from "@/views/ce-digital/sub-modules/customer-service/views/reports/report.service.js"
export default {
  data() {
    return {
      fields: Fields,
      filters: Filters,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search....",
        model: null,
      },
      totalRows: 0,
      startPage: 0,
      toPage: 0,
      isBusy: false,
      paginate: {
        currentPage: 1,
        perPage: 100,
      },
      showNote: false,
      textNote: "",
      textClient: "",
      dataNote: "",
      dataReport: [],
    }
  },
  computed: {
    subTotalSeconds() {
      if (this.dataReport.length > 0) {
        return this.dataReport[0].sub_total_seconds
      }
    },
    totalSeconds() {
      if (this.dataReport.length > 0) {
        return this.dataReport[0].total_seconds
      }
    },
  },
  methods: {
    async myProvider(ctx) {
      const params = {
        search_text: this.filterPrincipal.model,
        page: this.paginate.currentPage,
        per_page: this.paginate.perPage,
        order: ctx.sortDesc,
        order_by: ctx.sortBy,
        from: this.filters[0].model,
        to: this.filters[1].model,
        agent: this.filters[2].model,
      }

      const { data } = await ReportService.getReportAlertTimeCE(params)
      this.startPage = data.from
      this.paginate.currentPage = data.current_page
      this.paginate.perPage = data.per_page
      this.totalRows = data.total
      this.toPage = data.to
      this.dataReport = data.data
      return data.data || []
    },

    async getAgents() {
      const { data } = await amgApi.post("/credit-experts-digital/get-agents")
      this.filters[2].options = data.map((element) => ({
        ...element,
        name: element.name.split("(")[0],
      }))
    },
    convertSeconds(segundos) {
      var horas = Math.floor(segundos / 3600)
      var minutos = Math.floor((segundos % 3600) / 60)
      var segundosRestantes = segundos % 60

      var resultado = ""

      if (horas > 0) {
        resultado += horas + " hours, "
      }

      if (minutos > 0) {
        resultado += minutos + " minutes, "
      }

      resultado += segundosRestantes + " seconds"

      if (horas === 0 && minutos === 0) {
        resultado = segundosRestantes + " seconds"
      }

      return resultado
    },

    openModalNote(note, client) {
      this.textNote = note ? note : "<p>No content</p>"
      this.textClient = "Note of " + client.client
      this.showNote = true
      this.dataNote = client
    },

    regexNoHTML(text) {
      let newText = text.replace(/<[^>]*>/g, "")
      return newText
    },
  },

  async created() {
    await this.getAgents()
  },
}
</script>

<style>
.card-note {
  box-shadow: rgba(100, 100, 111, 0.2) -1px 4px 4px 0px !important;
}

.text-agent {
  color: rgb(186, 163, 69);
}
.w-70 {
  width: 70% !important;
  margin-bottom: 0.1rem;
}

.mr-jo {
  margin-top: 0.6rem;
}
</style>
