export default [
  {
    key: "lead_name",
    label: "Name",
    tdClass: "text-left",
    visible: true,
  },
  {
    key: "created_at",
    label: "Creation Date",
    visible: true,
  },
  {
    key: "mobile",
    label: "Mobile",
    visible: true,
  },
  {
    key: "account",
    label: "Account",
    visible: true,
  },
  {
    key: "ispayment",
    label: "PAID",
    visible: true,
  },
  {
    key: "call_round",
    label: "call round",
    thClass: "text-center",
    tdClass: "text-center pt-1",
    visible: true,
  },
  {
    key: "call_round_date",
    label: "call round date",
    thClass: "text-center",
    tdClass: "text-center pt-1",
    visible: true,
  },
];
