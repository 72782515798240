<template>
  <div>

    <b-row class="w-100 m-0">
      <b-col
        cols="12"
        class="pl-0 pr-0"
      >
        <b-input-group class="mr-1">
          <b-input-group-prepend>
            <b-button
              variant="outline-info"
            >SEARCH BY DIGITAL CLIENTS</b-button>
          </b-input-group-prepend>
          <b-form-input
            v-model="clientData.search"
            placeholder="Search by First Name, Last Name or the last 4 digits of the Phone Number"
            @keyup.enter="modalState = true"
          />
          <b-input-group-append>
            <b-button
              variant="primary"
              @click="modalState = true"
            >
              <feather-icon icon="SearchIcon" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>

    <modal-search-digital
      v-if="modalState"
      :search-data="clientData"
      @closeModal="modalState = false"
    />
  </div>
</template>

<script>
import ModalSearchDigital from './modal/ModalSearchDigital.vue'

export default {
  components: { ModalSearchDigital },
  data() {
    return {
      modalState: false,
      clientData: {
        search: '',
      },
    }
  },
}
</script>

<style>
</style>
