import { amgApiApp } from "@/service/axios";

class chatRevisionService {
  async changeService(params) {
    const { data } = await amgApiApp.post("/commissions/change-status", params);
    return data;
  }

  async getCommissions(params) {
    const { data } = await amgApiApp.post(
      "/commissions/get-commissions",
      params
    );
    return data;
  }

  async getCommissionMessages(params) {
    const { data } = await amgApiApp.post(
      "/commissions/get-commission-messages",
      params
    );
    return data;
  }

  async getTracking(id) {
    const { data } = await amgApiApp.get("/commissions/get-tracking/" + id);
    return data;
  }
}

export default new chatRevisionService();
