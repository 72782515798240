<template>
  <div>
    <b-table
      :items="roundsProvider"
      :fields="fields"
      ref="roundsTable"
      sticky-header="22rem"
      style="height: 22rem"
      show-empty
    >
      <template #empty>
        <div class="text-center text-primary my-2">
          <strong>No call rounds found</strong>
        </div>
      </template>
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-2"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
      <template #cell(lead_name)="data">
        <div class="d-flex flex-row">
          <icon-status :icon-status="data.item" class="mt-1" />
          <p
            class="pl-0 pt-1 m-0"
            :class="isDarkSkin ? 'text-warning' : 'text-primary'"
          >
            {{ data.item.lead_name }}
          </p>
        </div>
        <p class="m-0 pl-2">{{ data.item.account }}</p>
      </template>
      <template #cell(count_call)="data">
        <div class="d-flex justify-content-center">
          <b-badge
            pill
            variant="warning"
            v-if="data.item.count_call > 0"
            class="padding-pill"
          >
            {{ data.item.count_call }}</b-badge
          >
          <b-badge pill variant="secondary" class="padding-pill" v-else
            >0</b-badge
          >
        </div>
      </template>
      <template #cell(type_motive)="data">
        <b-badge
          pill
          :variant="`light-${typeMotive(data.item.motive_type)}`"
          class="text-capitalize w-100"
          >{{ data.item.motive_name }}</b-badge
        >
      </template>
      <template #cell(action)="data">
        <div style="padding: 3px" class="text-center">
          <b-button
            variant="outline"
            v-b-tooltip.hover.left="'Available'"
            @click="validateAccess(data.item)"
          >
            <feather-icon icon="PhoneCallIcon" class="text-success" />
          </b-button>
          <b-button
            variant="outline"
            v-b-tooltip.hover.left="'Not Available'"
            @click="notAvailable(data.item)"
          >
            <feather-icon icon="PhoneMissedIcon" class="text-warning" />
          </b-button>
          <b-button
            variant="outline"
            v-b-tooltip.hover.top="'Not answer'"
            @click="notAnswer(data.item)"
          >
            <feather-icon icon="PhoneOffIcon" class="text-danger" />
          </b-button>
        </div>
      </template>
    </b-table>

    <modal-reschedule
      v-if="showModalReschedule"
      :client-data="modalRescheduleInfo"
      @hideModal="showModalReschedule = false"
      @refresh="$refs['roundsTable'].refresh()"
    ></modal-reschedule>

    <modal-validate-access
      v-if="showModalAccess"
      :client-data="modalAccessInfo"
      @closeModal="hideModalAccess"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import CallRoundService from "@/views/ce-digital/sub-modules/customer-service/views/call-round/service/callround.service.js";

import ModalReschedule from "@/views/ce-digital/sub-modules/customer-service/views/call-round/view/components/ModalReschedule.vue";
import ModalValidateAccess from "@/views/commons/components/search-digital/modal/ModalValidateAccess.vue";
import IconStatus from "@/views/specialist-digital/views/process-cr/view/components/grids/IconStatus.vue";

export default {
  components: { ModalReschedule, ModalValidateAccess, IconStatus },
  data() {
    return {
      fields: [
        {
          key: "lead_name",
          label: "Name",
        },
        {
          key: "count_call",
          label: "Call Counter",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "mobile",
          label: "Phone",
          thClass: "text-center",
          tdClass: "text-center pt-1",
        },
        {
          key: "type_motive",
          label: "Motive",
          thClass: "text-center",
          tdClass: "text-center pt-1",
        },
        {
          key: "action",
          label: "Actions",
          thClass: "text-center",
          tdClass: "text-center  pt-1",
        },
      ],
      showModalReschedule: false,
      modalRescheduleInfo: {},
      showModalAccess: false,
      modalAccessInfo: {},
    };
  },
  methods: {
    ...mapActions({
      answerCallround: "DbAgentStore/A_ANSWER_CR",
      endCallround: "DbAgentStore/A_END_CR",
    }),
    async roundsProvider() {
      let params = {
        name_text: "",
        per_page: 10,
        page: 1,
        orderby: 1,
        order: "asc",
        status: 1,
      };
      const res = await CallRoundService.getClientCallRoundsCE(params);
      return res.data.data;
    },
    notAvailable(item) {
      this.modalRescheduleInfo = item;
      this.showModalReschedule = true;
    },
    async notAnswer(item) {
      try {
        const confirm = await this.showConfirmSwal(
          "Client does not answer",
          "Are you sure? You won't be able to revert this!"
        );
        if (confirm.value) {
          const params = {
            user_id: this.currentUser.user_id,
            client_id: item.client_account_id,
            status: 1,
            call_type: 1,
            call_round_id: item.call_round_id,
          };
          this.addPreloader();
          const { data, status } =
            await CallRoundService.setTrackingCallRoundCE(params);
          if (status == 200) {
            this.$refs.refCallroundsCE.refresh();
            this.removePreloader();
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    validateAccess(item) {
      let data = {
        lead_name: item.lead_name,
        client_account_id: item.client_account_id,
        call_round_id: item.call_round_id,
        client_id: item.client_id,
        typeAccess: item.motive_id,
        wayAccess: 2,
        hasAccess: true,
      };
      this.modalAccessInfo = data;
      this.showModalAccess = true;
      this.answerCallround();
    },
    hideModalAccess() {
      this.endCallround();
      showModalAccess = false;
    },
    typeMotive(type) {
      switch (type) {
        case 1:
          return "primary";
          break;
        case 2:
          return "warning";
          break;
        case 3:
          return "info";
          break;
        case 4:
          return "warning";
          break;
        case 5:
          return "success";
          break;
        case 6:
          return "success";
          break;
        case 7:
          return "info";
          break;
        default:
          break;
      }
    },
  },
};
</script>
