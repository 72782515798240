import { amgApi } from "@/service/axios";

class ReportService {
  async getReportAlertTimeCE(params) {
    try {
      const data = await amgApi.post(
        "credit-experts-digital/sales/get-report-alerts-ce",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
}

export default new ReportService();
